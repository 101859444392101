<template>
  <div class="container">
    <nav class="d-flex align-items-center mb-3 mb-lg-4" aria-label="breadcrumb">
      <router-link
        class="text-brown text-decoration-none mr-3"
        to="/loyalty-programs"
      >
        <svg
          class="mr-3"
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_15_368)">
            <path
              d="M31.866 29.046C31.916 28.71 32.975 20.636 28.227 15.08C25.31 11.666 20.809 9.79703 14.997 9.61203L14.966 3.99903C14.966 3.61303 14.743 3.26203 14.395 3.09603C14.046 2.93303 13.633 2.98003 13.334 3.22403L0.366998 13.866C0.134999 14.056 -1.52259e-06 14.34 -0.00100152 14.64C-0.00200152 14.94 0.131998 15.224 0.363998 15.414L13.332 26.157C13.63 26.404 14.047 26.455 14.393 26.289C14.744 26.124 14.966 25.771 14.966 25.385L14.997 19.646C29.006 19.636 30.002 28.612 30.036 28.983C30.079 29.487 30.398 29.88 30.904 29.896C30.916 29.897 30.927 29.897 30.938 29.897C31.433 29.897 31.792 29.536 31.866 29.046ZM13.261 17.922C13.073 18.11 12.967 18.364 12.967 18.63V23.268L2.578 14.646L12.967 6.10403V10.597C12.967 11.15 13.414 11.597 13.967 11.597C19.657 11.597 24.004 13.245 26.702 16.373C28.731 18.727 29.664 21.608 29.983 23.999C27.799 21.015 23.975 17.636 13.968 17.63C13.968 17.63 13.967 17.63 13.966 17.63C13.702 17.63 13.448 17.735 13.261 17.922Z"
              fill="#6B5045"
            />
          </g>
          <defs>
            <clipPath id="clip0_15_368">
              <rect width="32" height="32" fill="white" />
            </clipPath>
          </defs>
        </svg>
        返回
      </router-link>
      <ol class="breadcrumb bg-transparent p-0 mb-0">
        <li class="breadcrumb-item">
          <router-link to="/">主頁</router-link>
        </li>
        <li class="breadcrumb-item">
          <router-link to="/loyalty-programs">優惠券管理</router-link>
        </li>
        <li class="breadcrumb-item">創建</li>
      </ol>
    </nav>

    <b-alert show variant="danger" v-if="veeErrors.all().length">
      <ul class="list-unstyled mb-0">
        <li v-for="error in filterVeeErrors(veeErrors.all())" :key="error">
          {{ error }}
        </li>
      </ul>
    </b-alert>

    <div class="card rounded-0 border-0 p-3 p-lg-4">
      <b-form @submit.stop.prevent="onSubmitCreateProduct">
        <b-row class="mb-3">
          <b-col sm="6" lg="4" class="order-2 order-lg-1">
            <div
              class="preview-image d-flex align-items-center justify-content-center text-brown border shadow-sm mb-2 mr-lg-3"
              :style="{ 'background-image': `url(${previewImage})` }"
            >
              <span v-if="!previewImage">優惠券圖片</span>
            </div>
          </b-col>

          <b-col sm="6" lg="4" class="order-3 order-lg-2">
            <b-form-file
              id="previewImage"
              class="d-none"
              ref="fileImage"
              name="image"
              v-validate="{ required: false, ext: 'jpeg|jpg|png', size: 1024 }"
              :state="validateState('image')"
              aria-describedby="input-image-live-feedback"
              data-vv-as="image"
              @input="browseImage"
            ></b-form-file>
            <div class="mb-3">
              <label
                for="previewImage"
                class="bg-white text-brown border border-brown--light rounded-6px py-2 px-4"
                >上傳圖片</label
              >
            </div>
            <div>
              <div class="mb-1">只接受 jpg,jpeg,png格式</div>
              <div class="mb-1">建議尺寸 1,200 x 630 px</div>
              <div class="mb-1">最大上傳檔案 1Mb</div>
            </div>
          </b-col>
        </b-row>

        <b-row class="mb-3">
          <b-col sm="4">
            <label for="titleEn" class="text-brown"
              >標題<span class="text-danger">*</span></label
            >
          </b-col>
          <b-col sm="8" lg="6">
            <b-form-input
              id="titleEn"
              class="rounded-0"
              name="titleEn"
              v-model="formControl.titleEn"
              v-validate="{ required: true }"
              :state="validateState('titleEn')"
              aria-describedby="input-titleEn-live-feedback"
              data-vv-as="titleEn"
            ></b-form-input>
          </b-col>
        </b-row>

        <b-row class="mb-3">
          <b-col sm="4">
            <label for="titleZh" class="text-brown">標題 (简体版本)</label>
          </b-col>
          <b-col sm="8" lg="6">
            <b-form-input
              id="titleZh"
              class="rounded-0"
              name="titleZh"
              v-model="formControl.titleZh"
              v-validate="{ required: false }"
              :state="validateState('titleZh')"
              aria-describedby="input-titleZh-live-feedback"
              data-vv-as="titleZh"
            ></b-form-input>
          </b-col>
        </b-row>

        <b-row class="mb-3">
          <b-col sm="4">
            <label for="labelZh" class="text-brown"
              >有效期限天數<span class="text-danger">*</span></label
            >
          </b-col>
          <b-col sm="8" lg="6">
            <b-form-input
              v-validate="'required|integer'"
              id="validityPeriodDays"
              class="rounded-0"
              name="validityPeriodDays"
              v-model="formControl.validityPeriodDays"
              :state="validateState('validityPeriodDays')"
              aria-describedby="input-validityPeriodDays-live-feedback"
              data-vv-as="validityPeriodDays"
              type="number"
            ></b-form-input>
          </b-col>
        </b-row>

        <b-row class="mb-3">
          <b-col sm="4">
            <label
              for="discountType"
              class="text-brown"
            >
              折扣方式
            </label>
          </b-col>

          <b-col sm="8" lg="6">
            <b-form-select name="discountType" v-model="formControl.isPercentage" :options="options"></b-form-select>
          </b-col>
        </b-row>

        <b-row class="mb-3" v-show="formControl.isPercentage">
          <b-col sm="4">
            <label for="discountPercentage" class="text-brown"
              >百分比 (%)<span class="text-danger"
                >*</span
              ></label
            >
          </b-col>
          <b-col sm="8" lg="6">
            <b-form-input
              id="percentage"
              class="rounded-0"
              name="percentage"
              v-model="formControl.couponDiscountPercentage"
              v-validate="formControl.isPercentage ? 'required' :''"
              :state="validateState('percentage')"
              aria-describedby="input-percentage-live-feedback"
              data-vv-as="percentage"
              type="number"
            ></b-form-input>
          </b-col>
        </b-row>

        <b-row class="mb-3" v-show="!formControl.isPercentage">
          <b-col sm="4">
            <label for="couponDiscountValue" class="text-brown"
              >固定金額 (HKD)<span class="text-danger"
                >*</span
              ></label
            >
          </b-col>
          <b-col sm="8" lg="6">
            <b-form-input
              id="couponDiscountValue"
              class="rounded-0"
              name="couponDiscountValue"
              v-model="formControl.couponDiscountValue"
              v-validate="!formControl.isPercentage ? 'required' :''"
              :state="validateState('couponDiscountValue')"
              aria-describedby="input-couponDiscountValue-live-feedback"
              data-vv-as="couponDiscountValue"
              type="number"
            ></b-form-input>
          </b-col>
        </b-row>

        <b-row class="mb-3">
          <b-col sm="4">
            <label for="labelZh" class="text-brown"
              >Coupon threshold<span class="text-danger">*</span></label
            >
          </b-col>
          <b-col sm="8" lg="6">
            <b-form-input
              id="couponThreshold"
              class="rounded-0"
              name="couponThreshold"
              v-model="formControl.couponThreshold"
              v-validate="'required|min_value:0'"
              :state="validateState('couponThreshold')"
              aria-describedby="input-couponThreshold-live-feedback"
              data-vv-as="couponThreshold"
              type="number"
            ></b-form-input>
          </b-col>
        </b-row>

        <b-row class="mb-3">
          <b-col sm="4">
            <label for="tcEn" class="text-brown"
              >規則與條例<span class="text-danger">*</span></label
            >
          </b-col>
          <b-col sm="8" lg="6">
            <vue-editor
              id="tcEn"
              :editorToolbar="descriptionToolbar"
              name="tcEn"
              v-model="formControl.tcEn"
              v-validate="{ required: false, max: 2000 }"
              :state="validateState('tcEn')"
              data-vv-as="tcEn"
            ></vue-editor>
          </b-col>
        </b-row>

        <b-row class="mb-3">
          <b-col sm="4">
            <label for="tcZh" class="text-brown"
              >規則與條例 (简体版本)</label
            >
          </b-col>
          <b-col sm="8" lg="6">
            <vue-editor
              id="tcZh"
              :editorToolbar="descriptionToolbar"
              name="tcZh"
              v-model="formControl.tcZh"
              v-validate="{ required: false, max: 2000 }"
              :state="validateState('tcZh')"
              data-vv-as="tcZh"
            ></vue-editor>
          </b-col>
        </b-row>

        <b-row class="mb-3">
          <b-col sm="4">
            <label for="productIds" class="text-brown"
              >產品<span class="text-danger">*</span></label
            >
          </b-col>
          <b-col sm="8" lg="6">
            <div class="mb-4 mt-2">
              <div class="d-flex align-items-center">
                <div class="mr-2">為所有產品設定</div>
                <b-form-checkbox
                @change="handleCheckboxChange"
                  name="check-button"
                  class="custom-switch--success"
                  switch
                  v-model="formControl.isApplicableForAll"
                  :checked="formControl.isApplicableForAll"
                ></b-form-checkbox>
              </div>
            </div>
            <multiselect
             @select="onSelectOption"
              v-model="formControl.productIds"
              :placeholder="formControl.isApplicableForAll ? '選擇全部' : '選擇'"
              track-by="id"
              :options="categories"
              :multiple="true"
              :disabled="formControl.isApplicableForAll"
              :custom-label="nameFormatter"
              :close-on-select="false"
            >
            <template #option="{ option }">
            <div v-if="option.posId" class="d-flex align-items-center" style="gap:8px">
              <div v-if="!!option.imageUrl"><img :src="option.imageUrl[0]" width="30" height="30"></div>
              <div><span>{{ option.posId }}</span><span> - {{ option.nameZhHk }}</span></div>
            </div>
            <div v-else> {{ option.nameZhHk }}</div>
            </template>
          </multiselect>
            <small
              class="d-block text-danger"
              id="input-descriptionZh-live-feedback"
              v-if="noIdsSelected"
              >Please select product</small
            >
          </b-col>
        </b-row>

        <b-tabs class="tabs--form mb-4">
          <b-tab>
            <template #title>
              <div class="nav-item--text">
                描述<span class="text-danger ml-2">*</span>
              </div>
            </template>
            <b-form-textarea
              rows="3"
              max-rows="6"
              id="descriptionEn"
              class="rounded-0"
              name="descriptionEn"
              v-model="formControl.descriptionEn"
              v-validate="{ required: true, max: 2000 }"
              :state="validateState('descriptionEn')"
              aria-describedby="input-descriptionEn-live-feedback"
              data-vv-as="descriptionEn"
            ></b-form-textarea>
            <small
              class="d-block text-danger"
              id="input-descriptionEn-live-feedback"
              v-if="veeErrors.has('descriptionEn')"
              >{{ veeErrors.first("descriptionEn") }}</small
            >
          </b-tab>

          <b-tab>
            <template #title>
              <div class="nav-item--text">描述 (简体版本)</div>
            </template>
            <b-form-textarea
              rows="3"
              max-rows="6"
              id="descriptionZh"
              class="rounded-0"
              name="descriptionZh"
              v-model="formControl.descriptionZh"
              v-validate="{ required: false, max: 2000 }"
              :state="validateState('descriptionZh')"
              aria-describedby="input-descriptionZh-live-feedback"
              data-vv-as="descriptionZh"
            ></b-form-textarea>
            <small
              class="d-block text-danger"
              id="input-descriptionZh-live-feedback"
              v-if="veeErrors.has('descriptionZh')"
              >{{ veeErrors.first("descriptionZh") }}</small
            >
          </b-tab>
        </b-tabs>

        <b-button
          :disabled="!hasVeeErrors || isSubmitting "
          type="submit"
          variant="primary"
          class="rounded-10px float-right px-3 px-lg-5"
          > <b-spinner v-if="isSubmitting" small class="mr-2"></b-spinner>儲存</b-button
        >
      </b-form>
    </div>
  </div>
</template>

<script>
import { getAllProducts } from '@/services/product.service'
import couponService from '../../services/coupon.service'
import Multiselect from 'vue-multiselect'
import { VueEditor } from 'vue2-editor'

export default {
  name: 'ProductCreate',
  components: { Multiselect, VueEditor },
  data: () => ({
    previewImage: null,
    descriptionToolbar: [
      ['bold', 'italic', 'underline', 'blockquote'],
      [
        { indent: '-1' },
        { indent: '+1' }
      ],
      [
        { align: '' },
        { align: 'center' },
        { align: 'right' },
        { align: 'justify' }
      ],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { list: 'check' }
      ],
      ['link', 'image']
    ],
    options: [
      {
        value: true,
        text: 'Percentage'
      },
      {
        value: false,
        text: 'Fixed amount (HKD)'
      }
    ],
    filters: {
      name: '',
      start: '',
      end: ''
    },
    categories: [],
    isSubmitting: false,
    formControl: {
      couponDiscountPercentage: 0,
      couponDiscountValue: 0,
      isPercentage: true,
      currencyCode: 'HKD',
      descriptionEn: '',
      descriptionZh: '',
      imageURL: '',
      labelEn: '',
      labelZh: '',
      productIds: [],
      tcEn: '',
      tcZh: '',
      titleEn: '',
      titleZh: '',
      validityPeriodDays: 0,
      isApplicableForAll: false,
      couponThreshold: 0
    },
    imageFile: null,
    noIdsSelected: false
  }),

  mounted () {
    getAllProducts().then(
      res => {
        this.categories = res.data.data
        this.categories.unshift({
          id: -1,
          nameZhHk: '全選',
          selectAll: true
        })
      }
    )
  },

  computed: {
    hasVeeErrors: ({ veeErrors }) => veeErrors.all().length === 0
  },

  methods: {
    validateState (ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.veeErrors.has(ref)
      }
      return null
    },
    filterVeeErrors (veeErrors = []) {
      return veeErrors.filter((element, index) => {
        return veeErrors.indexOf(element) === index
      })
    },
    async onSubmitCreateProduct () {
      this.$validator.validateAll().then(async (result) => {
        this.noIdsSelected = !this.formControl.productIds.length && !this.formControl.isApplicableForAll
        if (!result || this.noIdsSelected) {
          return
        }

        if (this.formControl.isPercentage) {
          this.formControl.couponDiscountValue = 0
        } else {
          this.formControl.couponDiscountPercentage = 0
        }

        this.isSubmitting = true
        const formData = new FormData()
        formData.append('couponImage', this.imageFile)
        const imageURL = await couponService.uploadCouponImage(formData)
        this.formControl.imageURL = imageURL
        const isAppliedAllPro = this.categories.filter(
          (option) => !option.selectAll
        )
        if (this.formControl.isApplicableForAll || this.formControl.productIds.length === isAppliedAllPro.length) {
          this.formControl.productIds = null
          this.formControl.isApplicableForAll = true
        } else {
          this.formControl.productIds = this.formControl.productIds.map((item) => item.id)
        }
        await couponService.createCoupon(this.formControl).then(
          res => {
            if (res.success) {
              this.makeToast('success', '優惠券管理', '優惠券已創建成功')
              setTimeout(() => { this.$router.push('/loyalty-programs') }, 150)
            }
          },
          err => {
            this.makeToast('danger', '發生錯誤，請稍後再試。', err)
          }
        )
        this.isSubmitting = false
      })
    },
    makeToast (variant = null, title = 'Title', message = 'Message!') {
      this.$bvToast.toast(message, {
        title: title,
        autoHideDelay: 3000,
        variant: variant,
        solid: true
      })
    },
    handleCheckboxChange (value) {
      if (value) {
        this.formControl.productIds = []
        this.noIdsSelected = false
      }
    },

    browseImage () {
      const selectedFileInput = this.$refs.fileImage.files[0]
      if (selectedFileInput) {
        const reader = new FileReader()
        reader.onload = e => {
          this.previewImage = e.target.result
          this.imageFile = selectedFileInput
        }
        reader.readAsDataURL(selectedFileInput)
        this.$emit('input', selectedFileInput)
      }
    },
    nameFormatter (option) {
      if (!option.posId) return ''
      return `${option.posId} - ${option.nameZhHk}`
    },
    onSelectOption (selected) {
      this.noIdsSelected = !this.formControl.productIds.length
      if (selected.selectAll) {
        if (
          (this.formControl.productIds.length === this.categories.length - 1) ||
          (this.formControl.productIds.length === this.categories.length)
        ) {
          this.formControl.productIds = []
        } else {
          this.formControl.productIds = this.categories.filter(
            (option) => !option.selectAll
          )
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
.preview-image {
  width: 218px;
  height: 160px;
  background-size: cover;
  background-position: center center;
}
</style>
